<template>
  <div class="full-wp">
    <div class="steps-wp flex center">
      <span
        v-for="v in 2"
        :key="v"
        :class="{ [`content_${v}`]: true, active: stepsCur >= v }"
        class="steps-item">{{v}}</span>
    </div>
    <div class="pb40">
      <div v-show="stepsCur === 1" class="steps_2">
        <el-form
          :model="params"
          :rules="rules"
          ref="form"
          class="t-form"
          label-width="84px"
          label-position="left"
          size="medium"
          hide-required-asterisk>
          <el-form-item label="当前套餐">
            <div class="flex ai_c">
              <div
                class="type-item bg_2 active pointer not-select" />
            </div>
          </el-form-item>
          <el-form-item v-if="params.type !== 0" label="开通年限" prop="year">
            <div class="flex ai_c">
              <div
                @click="params.year = v"
                v-for="v in 3"
                :key="v"
                :class="{ active: v === params.year }"
                class="year-item pointer not-select">
                {{ v }}年
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="flex jc_b ai_s pt30">
          <div>
            <div class="flex ai_c fs20 bold">
              <span>订单金额 (元)：</span>
              <span v-if="params.type !== null" class="fs28 orange">{{ amount * (userCount || 0) * (params.year || 0) | formatMoney}}</span>
            </div>
            <div @click="isApply = !isApply" class="flex ai_c pointer not-select mt10">
              <i :class="{ active: isApply }" class="check-btn" />
              <p @click.stop="goPayAgreement" class="fs16 blue">壹卷付费开通协议</p>
            </div>
            <div class="group-purchase">
              <p>*开通两人及以上尊享团购价1680元/年/人</p>
              <p>支付完成后请联系客服13535158046</p>
            </div>
          </div>
          <div class="flex ai_c">
            <img
              src="~@/assets/image/buy/img_zhifubao.png"
              class="not-select mr20"
              width="34px"
              draggable=false />
            <t-btn @click="goPay" class="bold">前往支付</t-btn>
          </div>
        </div>
      </div>
      <div v-show="stepsCur === 2" class="steps_3 flex column ai_c pt50">
        <i :class="`icon__${resultType}`" class="icon" />
        <span class="fs18 lh25 bold blue">{{ resultType === 'success' ? '恭喜，账户开通成功！' : '很遗憾，交易失败！'}}</span>
        <el-table :data="[orderInfo]" class="t-table" style="width: auto" border>
          <el-table-column
            v-for="(v, i) in tableLabelList"
            :key="i"
            :label="v.label"
            :width="v.width">
            <template slot-scope="scope">
              <span>{{ scope.row[v.key] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <t-btn @click="$router.go(-1)" class="bold">返回</t-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { isMobile } from '@/assets/javascript/util'
import message from '@/mixins/message'

export default {
  mixins: [message],
  data() {
    return {
      // 步骤
      stepsCur: 1,
      // 数据
      params: {
        year: null
      },
      rules: {
        year: [
          { required: true, message: '请选择开通年限' },
        ]
      },
      userCount: 1,
      amount: 0,
      // 协议同意
      isApply: false,
      tableLabelList: [
        {
          label: '开通人数',
          key: 'user_count',
          width: 120
        },{
          label: '开通年限',
          key: 'year',
          width: 120
        },{
          label: '订单金额 (元)',
          key: 'amount',
          width: 210
        }
      ],
      resultType: null,
      orderInfo: {}
    }
  },
  methods: {
    ...mapMutations(['SETLOADING']),
    // 点击协议跳转
    goPayAgreement() {
      let link = this.$router.resolve({ name:"agreement" });
      window.open(link.href, "_blank");
      // console.info('goPayAgreement')
    },
    // 点击支付
    goPay() {
      const { isApply } = this

      this.$refs.form.validate(valid => {
        if (valid) {
          if (isApply) {
            this.renewPersonal()
          } else {
            this.error({
              message: '购买提醒',
              tip: '阅读《壹卷付费开通协议》并勾选同意协议后，再进行支付购买',
              duration: 0
            })
          }
        }
      })
    },
    // 付款版
    renewPersonal() {
      const { params } = this
      const { id } = this.$route.query

      this.$api.order.renewPersonal({
        ...params,
        assignId: Number(id)
      }).then(res => {
        window.open(res.payParams)
        this.SETLOADING({
          title: '正在支付…',
          message: '订单支付正在进行中，请稍等'
        })
        setTimeout(() => {
          this.getOrderStatus(res.orderNo)
        }, 800)
      })
    },
    // 获取套餐信息
    getConfig() {
      this.$api.order.getConfig({
        tyep: 'pay',
        key: 'amount'
      }).then(res => {
        const amount = res.filter(v => v.key === 'amount')[0].value

        this.amount = Number(amount)
      })
    },
    // 获取订单
    getOrderStatus(orderNo) {
      this.$api.order.getOrderStatus({ orderNo }).then(res => {
        if (res.pay_status === 1) {
          this.getOrderStatus(orderNo)
          return
        }

        if (res.pay_status === 2) {
          this.resultType = 'success'
        } else {
          this.resultType = 'error'
        }

        this.getOrderInfo(orderNo)
        this.stepsCur = this.stepsCur + 1
        this.SETLOADING(null)
      }).catch(() => {
        this.getOrderStatus(orderNo)
      })
    },
    // 获取订单信息
    getOrderInfo(orderNo) {
      this.$api.order.getOrderInfo({ orderNo }).then(res => {
        this.orderInfo = res
      })
    }
  },
  created() {
    this.getConfig()
  }
}
</script>

<style lang="scss" scoped>
  .full-wp {
    background: #fff;
    .steps-wp {
      width: 100%;
      height: 146px;
      border-bottom: 1px solid $line;
      .steps-item {
        position: relative;
        width: 29px;
        height: 29px;
        border-radius: 50%;
        text-align: center;
        line-height: 29px;
        font-size: 14px;
        color: #fff;
        background: $tertiary_text;
        &:not(:first-child) {
          margin-left: 262px;
          &::after {
            content: '';
            position: absolute;
            width: 250px;
            height: 1px;
            background: $border;
            top: 0;
            bottom: 0;
            left: -6px;
            transform: translateX(-100%);
            margin: auto 0;
          }
        }
        &::before {
          content: '';
          position: absolute;
          text-align: center;
          width: 250px;
          font-size: 14px;
          font-weight: 400;
          color: $tertiary_text;
          left: 50%;
          bottom: -6px;
          transform: translate(-50%, 100%);
          margin: 0 auto;
        }
        &.content_1::before {
          content: '确认支付订单';
        }
        &.content_2::before {
          content: '购买结果';
        }
        &.active {
          background: $primary;
          &:not(:first-child) {
            &::after {
              background: $primary;
            }
          }
          &::before {
            color: $primary;
            font-weight: 600;
          }
        }
      }
    }
    .steps_2 {
      width: 750px;
      padding-top: 60px;
      margin: 0 auto;
      transform: translateX(56px);
      .t-form {
        padding-bottom: 30px;
        border-bottom: 1px dashed $line;
        ::v-deep .el-form-item {
          position: relative;
        }
        ::v-deep .el-form-item__label {
          float: none;
          position: absolute;
          vertical-align: baseline;
          line-height: normal;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .input-width {
          width: 310px;
        }
        .form-tip {
          font-size: 16px;
          font-weight: 400;
          color: $tertiary_text;
        }
        .type-item {
          width: 130px;
          height: 60px;
          background-repeat: no-repeat;
          background-size: 100%;
          opacity: .25;
          &:not(:last-child) {
            margin-right: 20px;
          }
          &.bg_1 {
            background-image: url('~@/assets/image/buy/goumai_taocan_tiyan_img.png');
          }
          &.bg_2 {
            background-image: url('~@/assets/image/buy/goumai_taocan_vip_img.png');

          }
          &.active {
            opacity: 1;
          }
        }
        .year-item {
          position: relative;
          width: 90px;
          height: 40px;
          background: #FFFFFF;
          padding-left: 36px;
          font-size: 16px;
          font-weight: 400;
          line-height: 37.5px;
          color: $primary_text;
          border-radius: 4px;
          border: 1px solid $border;
          &::after {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            border: 1px solid $border;
            border-radius: 50%;
            left: 14px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            box-sizing: border-box;
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
          &.active {
            font-weight: 600;
            color: $primary;
            border-color: $primary;
            &::after {
              border: 5px solid $primary;
            }
          }
        }
      }
      .check-btn {
        width: 14px;
        height: 14px;
        border: 1px solid $border;
        border-radius: 2px;
        margin-right: 8px;
        &.active {
          border: none;
          background: url('~@/assets/image/buy/btn_tick_on.png') no-repeat;
          background-size: 100%;
        }
      }
    }
    .steps_3 {
      > .icon {
        width: 50px;
        height: 50px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-bottom: 13px;
        &.icon__success {
          background-image: url('~@/assets/image/global/popup_img_success.png');
        }
        &.icon__error {
          background-image: url('~@/assets/image/global/popup_img_error.png');
        }
      }
      .t-table {
        margin-top: 24px;
        margin-bottom: 40px;
        &.el-table {
          border-color: $border !important;
          &.el-table--group::after,
          &.el-table--border::after,
          &.el-table::before {
            background-color: $border !important;
          }
          ::v-deep th, ::v-deep td {
            padding: 10px 0;
            border-color: $border !important;
            .cell {
              padding: 0 20px;
            }
          }
          ::v-deep td {
            color: $primary_text;
            background: transparent;
            .cell {
              font-size: 16px;
              font-weight: 800;
              line-height: 22px;
            }
          }
          ::v-deep th {
            color: #fff;
            background: transparent;
            .cell {
              font-size: 14px;
              line-height: 20px;
            }
          }
          ::v-deep .el-table__header {
            tr {
              background: linear-gradient(123deg, #3E517A 0%, #2E3F63 100%);
            }
          }
        }
      }
    }
    .group-purchase {
      flex: 1;
      padding-top:7px;
      color: #888888;
      > p {
        &:last-child {
          padding-left: 6px;
        }
      }
    }
  }
</style>