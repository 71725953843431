<template>
  <div class="full-wp">
    <div class="steps-wp flex center">
      <span
        v-for="v in 3"
        :key="v"
        :class="{ [`content_${v}`]: true, active: stepsCur >= v }"
        class="steps-item">{{v}}</span>
    </div>
    <div class="pb40">
      <div v-show="stepsCur === 1" class="steps_1 flex column ai_c pt40">
        <!-- <p class="steps_1-title">套餐选择</p>
        <span class="steps_1-tip">可以根据您的团队规模与业务需求，选择相应套餐</span> -->
        <div class="flex ai_s pt50">
          <div
            @click="params.type = v.type"
            v-for="(v, i) in cellList"
            :key="i"
            :class="{ active: v.type === params.type }"
            class="cell-item"
            :style="{ 'padding-top' : v.type==1 ? '135px' : '' }">
            <div
              :class="{ [`bg_${i + 1}`]: true }"
              :style="{ 'height' : v.type==1 ? '135px' : '' }"
              class="cell-hd flex column center">
              <p>{{ v.name }}</p>
              <span v-if="v.tip">
                {{v.tip}}
                <p v-if="v.type==1" style="text-align:center;font-size:12px;text-decoration:line-through;margin-top:7px;height:21px">原价: {{v.initAmount}}元/年/人</p>
                <p v-if="v.type==1" style="text-align:center;font-size:14px;margin-top:3px">折扣价: {{v.amount}}元/年/人</p>
                <p v-if="v.type==1" style="text-align:center;font-size:14px;margin-top:5px">拼团价: 1680元/年/人</p>
              </span>
            </div>
            <div class="pl20 "><!-- pr20 -->
              <div class="cell-summary flex column jc_c">
                <span v-for="item in v.summaryList" :key="item" class="summary-item">{{ item }}</span>
              </div>
              <div class="pt14 pb20" style="width:200px;border-top:1px dashed #E4E7ED;">
                <t-btn
                  @click="opening"
                  class="bold"
                  w="100%"
                  h="36px"
                  fs="16px"
                  type="primary">立即开通</t-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="stepsCur === 2" class="steps_2">
        <el-form
          :model="params"
          :rules="rules"
          ref="form"
          class="t-form"
          label-width="84px"
          label-position="left"
          size="medium"
          hide-required-asterisk>
          <el-form-item label="当前套餐" prop="type">
            <div class="flex ai_c">
              <div
                @click="params.type = v.type"
                v-for="(v, i) in cellList"
                :key="i"
                :class="{ [`bg_${i + 1}`]: true, active: v.type === params.type }"
                class="type-item pointer not-select" />
            </div>
          </el-form-item>
          <el-form-item label="小组名称" prop="groupName">
            <el-input
              v-model="params.groupName"
              class="input-width"
              placeholder="请输入小组名称"/>
            <span class="form-tip ml14">*团队用户输入团队名称，个人用户输入本人名字</span>
          </el-form-item>
          <el-form-item label="开通人数" prop="userCount">
            <el-input
              v-model="params.userCount"
              oninput="value=value.replace(/[^\d]/g,'')"
              class="input-width"
              placeholder="请输入开通人数"/>
            <span class="form-tip ml14">*一次开通多人后，前往系统进行分配</span>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="params.phone"
              maxlength="11"
              oninput="value=value.replace(/[^\d]/g,'')"
              class="input-width"
              placeholder="请输入手机号码"/>
            <span class="form-tip ml14">*开通多人时，此号码用户默认为小组管理员</span>
          </el-form-item>
          <el-form-item v-if="params.type !== 0" label="开通年限" prop="year">
            <div class="flex ai_c">
              <div
                @click="params.year = v"
                v-for="v in 3"
                :key="v"
                :class="{ active: v === params.year }"
                class="year-item pointer not-select">
                {{ v }}年
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="flex jc_b ai_s pt30">
          <div>
            <div class="flex ai_c fs20 bold">
              <span>订单金额 (元)：</span>
              <span v-if="params.type !== null" class="fs28 orange">{{ cellList[params.type].amount * (params.userCount || 0) * (params.year || 0) | formatMoney}}</span>
            </div>
            <div @click="isApply = !isApply" class="flex ai_c pointer not-select mt10">
              <i :class="{ active: isApply }" class="check-btn" />
              <p @click.stop="goPayAgreement" class="fs16 blue">壹卷付费开通协议</p>
            </div>
            <div class="group-purchase">
              <p>*开通两人及以上尊享团购价1680元/年/人</p>
              <p>支付完成后请联系客服13535158046</p>
            </div>
          </div>
          <div class="flex ai_c">
            <img
              src="~@/assets/image/buy/img_zhifubao.png"
              class="not-select mr20"
              width="34px"
              draggable=false />
            <t-btn :disabled=!isApply @click="goPay" class="bold">前往支付</t-btn>
          </div>
        </div>
      </div>
      <div v-show="stepsCur === 3" class="steps_3 flex column ai_c pt50">
        <i :class="`icon__${resultType}`" class="icon" />
        <span class="fs18 lh25 bold blue">{{ resultType === 'success' ? '恭喜，账户开通成功！' : '很遗憾，交易失败！'}}</span>
        <el-table :data="[orderInfo]" class="t-table" style="width: auto" border>
          <el-table-column
            v-for="(v, i) in tableLabelList"
            :key="i"
            :label="v.label"
            :width="v.width">
            <template slot-scope="scope">
              <span>{{ scope.row[v.key] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <t-btn @click="$router.push({ name: 'login' })" class="bold">前往登录</t-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { isMobile } from '@/assets/javascript/util'
import message from '@/mixins/message'

export default {
  mixins: [message],
  data() {
    const userCount = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('开通人数需至少一个'))
      } else {
        callback()
      }
    }
    const phone = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      // 步骤
      stepsCur: 1,
      // 套餐
      cellList: [
        {
          name: '体验版',
          tip: '*免费体验15天，体验到期后，系统将保存30天数据',
          // tip: '*免费体验24小时，体验到期后，系统将保存30天数据',
          type: 0,
          amount: 0,
          initAmount : 0,
          summaryList: [
            '案卷云端存储',
            '线上协同办公',
            '案件流程管理',
            '阅卷系统',
            '法规案例检索（简化版）',
            '量刑预测系统（简化版）',
            '业务交流平台（浏览版）',
            '案件协作平台（浏览版）',
            '团队日程管理',
            '刑事文书模板'
          ]
        },
        {
          name: '付费版',
          tip: ' ',
          type: 1,
          amount: 0,
          initAmount : 0,
          summaryList: [
            '案卷云端存储',
            '线上协同办公',
            '案件流程管理',
            '阅卷系统',
            '法规案例检索（VIP版）',
            '量刑预测系统（VIP版）',
            '业务交流平台',
            '案件协作平台',
            '团队日程管理',
            '刑事文书模板'
            // '日程管理',
            // '案件管理',
            // '阅卷系统',
            // '文书模板',
            // '数据检索VIP版',
            // '量刑预测VIP版',
            // '文档校对WORD插件',
            // '专属客服'
          ]
        },
      ],
      // 数据
      params: {
        type: null,
        groupName: null,
        userCount: null,
        phone: null,
        year: null
      },
      rules: {
        type: [
          { required: true, message: '请选择套餐' }
        ],
        groupName: [
          { required: true, message: '请输入小组名称' }
        ],
        userCount: [
          { required: true, message: '请输入开通人数' },
          { validator: userCount }
        ],
        phone: [
          { required: true, message: '请输入手机号码' },
          { validator: phone }
        ],
        year: [
          { required: true, message: '请选择开通年限' },
        ]
      },
      // 协议同意
      isApply: false,
      tableLabelList: [
        {
          label: '开通人数',
          key: 'user_count',
          width: 120
        },{
          label: '开通年限',
          key: 'year',
          width: 120
        },{
          label: '订单金额 (元)',
          key: 'amount',
          width: 210
        }
      ],
      orderNo: '',
      resultType: 'success',
      orderInfo: {}
    }
  },
  methods: {
    ...mapMutations(['SETLOADING']),
    getTipText(item){
      console.log(item);
      return item.tip;
    },
    // 点击协议跳转
    goPayAgreement() {
      let link = this.$router.resolve({ name:"agreement" });
      window.open(link.href, "_blank");
      // console.info('goPayAgreement')
    },
    // 点击支付
    goPay() {
      const { params, isApply } = this

      this.$refs.form.validate(valid => {
        if (valid) {
          if (isApply) {
            params.type === 0 ? this.experience() : this.pay()
          } else {
            this.error({
              message: '购买提醒',
              tip: '阅读《壹卷付费开通协议》并勾选同意协议后，再进行支付购买',
              duration: 0
            })
          }
        }
      })
    },
    // 体验版请求
    experience() {
      const { params: {
        groupName,
        userCount,
        phone
      } } = this

      this.$api.order.experience({
        groupName,
        phone,
        userCount: Number(userCount)
      }).then(res => {
        this.resultType = 'success'
        this.getOrderInfo(res.orderNo)
        this.stepsCur = this.stepsCur + 1
      })
    },
    // 付款版
    pay() {
      const { params } = this

      this.$api.order.pay(params).then(res => {
        window.open(res.payParams)
        this.SETLOADING({
          title: '正在支付…',
          message: '订单支付正在进行中，请稍等'
        })
        setTimeout(() => {
          this.getOrderStatus(res.orderNo)
        }, 800)
      })
    },
    // 获取套餐信息
    getConfig() {
      this.$api.order.getConfig({
        tyep: 'pay',
        key: 'amount'
      }).then(res => {
        const amount = res.filter(v => v.key === 'amount')[0].value;
        const initAmount = res.filter(v => v.key === 'init_amount')[0].value;

        this.cellList[1].amount = Number(amount)
        this.cellList[1].initAmount = Number(initAmount)
      })
    },
    // 获取订单
    getOrderStatus(orderNo) {
      this.$api.order.getOrderStatus({ orderNo }).then(res => {
        if (res.pay_status === 1) {
          this.getOrderStatus(orderNo)
          return
        }

        if (res.pay_status === 2) {
          this.resultType = 'success'
        } else {
          this.resultType = 'error'
        }

        this.getOrderInfo(orderNo)
        this.stepsCur = this.stepsCur + 1
        this.SETLOADING(null)
      }).catch(() => {
        this.getOrderStatus(orderNo)
      })
    },
    // 获取订单信息
    getOrderInfo(orderNo) {
      this.$api.order.getOrderInfo({ orderNo }).then(res => {
        this.orderInfo = res
      })
    },
    // 点击立即开通
    opening() {
      this.stepsCur = 2

      setTimeout(() => {
        if (this.params.type) {
          this.$api.order.censusBuy()
        }
      }, 400)
    }
  },
  created() {
    this.getConfig()
  }
}
</script>

<style lang="scss" scoped>
  .full-wp {
    background: #fff;
    .steps-wp {
      width: 100%;
      height: 146px;
      border-bottom: 1px solid $line;
      .steps-item {
        position: relative;
        width: 29px;
        height: 29px;
        border-radius: 50%;
        text-align: center;
        line-height: 29px;
        font-size: 14px;
        color: #fff;
        background: $tertiary_text;
        &:not(:first-child) {
          margin-left: 262px;
          &::after {
            content: '';
            position: absolute;
            width: 250px;
            height: 1px;
            background: $border;
            top: 0;
            bottom: 0;
            left: -6px;
            transform: translateX(-100%);
            margin: auto 0;
          }
        }
        &::before {
          content: '';
          position: absolute;
          text-align: center;
          width: 250px;
          font-size: 14px;
          font-weight: 400;
          color: $tertiary_text;
          left: 50%;
          bottom: -6px;
          transform: translate(-50%, 100%);
          margin: 0 auto;
        }
        &.content_1::before {
          content: '选择套餐';
        }
        &.content_2::before {
          content: '确认支付订单';
        }
        &.content_3::before {
          content: '购买结果';
        }
        &.active {
          background: $primary;
          &:not(:first-child) {
            &::after {
              background: $primary;
            }
          }
          &::before {
            color: $primary;
            font-weight: 600;
          }
        }
      }
    }
    .steps_1 {
      &-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 42px;
        color: $primary_title;
        margin-bottom: 4px;
      }
      &-tip {
        font-size: 18px;
        font-weight: 400;
        color: $tertiary_text;
        line-height: 25px;
      }
      .cell-item {
        position: relative;
        width: 240px;
        padding-top: 105px;
        background: #FFFFFF;
        border-radius: 12px;
        border: 1px solid $border;
        &:not(:last-child) {
          margin-right: 80px;
        }
        &:first-child.active {
          border-color: $primary;
        }
        &:last-child.active {
          border-color: #f19900;
        }
        &.active {
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
        }
        .cell-hd {
          position: absolute;
          width: 240px;
          height: 105px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 12px 12px 0 0;
          left: -1px;
          top: -2px;
          z-index: 1;
          &.bg_1 {
            background-image: url('~@/assets/image/buy/taocan_tiyan_bg.png');
          }
          &.bg_2 {
            background-image: url('~@/assets/image/buy/taocan_vip_bg.png');
          }
          > p {
            font-size: 20px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 28px;
          }
          > span {
            font-size: 11px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            padding: 0 20px;
          }
        }
        .cell-summary {
          min-height: 277px;
          // padding: 30px 0;
          padding: 20px 0;
          padding-left: 4px;
          // border-bottom: 1px dashed $line;
          font-size: 0;
          .summary-item {
            position: relative;
            // padding-left: 30px;
            padding-left: 25px;
            font-size: 16px;
            font-weight: 400;
            color: $primary_title;
            line-height: 21px;
            &:not(:last-child) {
              margin-bottom: 15px;
            }
            &::after {
              content: '';
              position: absolute;
              width: 16px;
              height: 16px;
              background: url('~@/assets/image/buy/btn_tick_on.png') no-repeat;
              background-size: 100%;
              left: 0;
              top: 2.5px;
            }
          }
        }
      }
    }
    .steps_2 {
      width: 755px;
      padding-top: 60px;
      margin: 0 auto;
      transform: translateX(56px);
      .t-form {
        padding-bottom: 30px;
        border-bottom: 1px dashed $line;
        ::v-deep .el-form-item {
          position: relative;
        }
        ::v-deep .el-form-item__label {
          float: none;
          position: absolute;
          vertical-align: baseline;
          line-height: normal;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .input-width {
          width: 310px;
        }
        .form-tip {
          font-size: 16px;
          font-weight: 400;
          color: $tertiary_text;
        }
        .type-item {
          width: 130px;
          height: 60px;
          background-repeat: no-repeat;
          background-size: 100%;
          opacity: .25;
          &:not(:last-child) {
            margin-right: 20px;
          }
          &.bg_1 {
            background-image: url('~@/assets/image/buy/goumai_taocan_tiyan_img.png');
          }
          &.bg_2 {
            background-image: url('~@/assets/image/buy/goumai_taocan_vip_img.png');

          }
          &.active {
            opacity: 1;
          }
        }
        .year-item {
          position: relative;
          width: 90px;
          height: 40px;
          background: #FFFFFF;
          padding-left: 36px;
          font-size: 16px;
          font-weight: 400;
          line-height: 37.5px;
          color: $primary_text;
          border-radius: 4px;
          border: 1px solid $border;
          &::after {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            border: 1px solid $border;
            border-radius: 50%;
            left: 14px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            box-sizing: border-box;
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
          &.active {
            font-weight: 600;
            color: $primary;
            border-color: $primary;
            &::after {
              border: 5px solid $primary;
            }
          }
        }
      }
      .check-btn {
        width: 14px;
        height: 14px;
        border: 1px solid $border;
        border-radius: 2px;
        margin-right: 8px;
        &.active {
          border: none;
          background: url('../../assets/image/buy/btn_tick_on.png') no-repeat;
          background-size: 100%;
        }
      }
    }
    .steps_3 {
      > .icon {
        width: 50px;
        height: 50px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-bottom: 13px;
        &.icon__success {
          background-image: url('../../assets/image/global/popup_img_success.png');
        }
        &.icon__error {
          background-image: url('../../assets/image/global/popup_img_error.png');
        }
      }
      .t-table {
        margin-top: 24px;
        margin-bottom: 40px;
        &.el-table {
          border-color: $border !important;
          &.el-table--group::after,
          &.el-table--border::after,
          &.el-table::before {
            background-color: $border !important;
          }
          ::v-deep th, ::v-deep td {
            padding: 10px 0;
            border-color: $border !important;
            .cell {
              padding: 0 20px;
            }
          }
          ::v-deep td {
            color: $primary_text;
            background: transparent;
            .cell {
              font-size: 16px;
              font-weight: 800;
              line-height: 22px;
            }
          }
          ::v-deep th {
            color: #fff;
            background: transparent;
            .cell {
              font-size: 14px;
              line-height: 20px;
            }
          }
          ::v-deep .el-table__header {
            tr {
              background: linear-gradient(123deg, #3E517A 0%, #2E3F63 100%);
            }
          }
        }
      }
    }
    .group-purchase {
      flex: 1;
      padding-top:7px;
      color: #888888;
      > p {
        &:last-child {
          padding-left: 6px;
        }
      }
    }
  }
</style>